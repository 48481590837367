import { createStore } from 'vuex'

import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

import socket from './modules/socket/socket'



import upload_image   from './modules/upload_image/upload_image'
import feedback       from './modules/feedback/feedback'
import langs          from './modules/langs/langs'
import templates      from './modules/templates/templates'
import users          from './modules/users/users'
import profiles       from './modules/profiles/profiles'
import games          from './modules/games/games'
import steps          from './modules/steps/steps'
import tacts          from './modules/tacts/tacts'
import teams          from './modules/teams/teams'
import historys       from './modules/historys/historys'
import tabs           from './modules/tabs/tabs'
import operations     from './modules/operations/operations'

import assistant     from './modules/assistant/assistant'



export default createStore({

  state: {

    domain: window.location.hostname == "localhost" ? "http://localhost" : "https://factory.bsgames.ru",

    err: {
      is: false,
      name: "",
    },
    
    rules:        false,
    fast_credit:  false,
    chart:        false,

    global_block_window: false,

    //??? - надо придумать как лучше сделать
    pop_up: {
      active: false,
      name: '',
      id: 0, 
    },

    forecasts: [],

    //переменная нужна для сокетов, на какой страничке находится юзер
    where_is: '',

    time: "00:00:00",

    admin_view_games: false,
    
  },

  getters,
  mutations,
  actions,
  modules: {
    
    socket,

    upload_image,
    feedback,

    langs,
    templates,
    profiles,
    users,
    
    games,
    steps,
    tacts,
    teams,
    historys,
    tabs,
    operations,
    assistant,

  },

})


